<template>
  <div class="picture-preview bg-dark">
    <div class="preview-works">
      <div class="video-box">
        <div class="time" v-if="infoData.create_time">
          上传时间：{{ infoData.create_time }}
        </div>
        <div class="full-screen-mask"></div>
        <video
          @contextmenu="handleMouse"
          :src="infoData.resource_path"
          :autoplay="true"
          controlslist="nodownload nofullscreen"
          controls
          loop="loop"
          class="video"
        >
          您的浏览器不支持 video 标签。
        </video>
      </div>
      <div class="other-picture common-part">
        <div class="title">
          <span>其他作品</span>
          <span class="more" @click="lookMore()">查看更多>></span>
        </div>
        <el-row
          style="margin:0 -14px;"
          :gutter="10"
          v-if="infoData.other_resource && infoData.other_resource.length > 0"
        >
          <el-col
            class="grid-content-box"
            :span="6"
            v-for="(item, index) in infoData.other_resource"
            :key="index"
            ><div
              class="grid-content"
              style="margin-bottom: 28px;"
              @mouseenter="mouseenter(item.id)"
              @mouseleave="mouseleave(item.id)"
            >
              <el-image
                style="height: 175px;min-width: 100%"
                :src="item.resource_fimage"
                @contextmenu="handleMouse"
                :fit="'cover'"
                lazy
                :class="{ 'current-image': item.id === current }"
              ></el-image>
              <div class="sample-player-box" @click="handleDetail(item.id)">
                <div class="info-block right-top">{{ item.quality }}</div>
                <div class="info-block left-top">{{ item.duration }}</div>
                <div class="video-player">
                  <video
                    @contextmenu="handleMouse"
                    :src="item.resource_path"
                    :autoplay="true"
                    loop="loop"
                    class="video"
                    v-if="item.id === current"
                  >
                    您的浏览器不支持 video 标签。
                  </video>
                </div>
              </div>
              <div class="grid-text">
                <div class="grid-oper">
                  <p class="name">{{ item.title }}</p>
                  <div>
                    <img
                      src="@/assets/images/collection-1.png"
                      @click="handleCollection(item.id)"
                    />
                    <img
                      src="@/assets/images/forward-1.png"
                      @click="handleShare(item)"
                    />
                    <img
                      v-if="item.is_zan"
                      src="@/assets/images/like-fill.png"
                      @click="handleLike(item.id, infoData.other_resource)"
                    />
                    <img
                      v-else
                      src="@/assets/images/like-1.png"
                      @click="handleLike(item.id, infoData.other_resource)"
                    />
                    <span>{{ item.fabulous }}</span>
                  </div>
                </div>
              </div>
            </div></el-col
          >
        </el-row>
        <div v-else class="empty-text">暂时还没有相关信息</div>
      </div>
      <div class="similar-picture common-part">
        <div class="title">
          <span>相关视频</span>
        </div>
        <el-row
          style="margin:0 -14px;"
          :gutter="10"
          v-if="infoData.similarresource && infoData.similarresource.length > 0"
        >
          <el-col
            class="grid-content-box"
            :span="6"
            v-for="(item, index) in infoData.similarresource"
            :key="index"
            ><div
              class="grid-content"
              style="margin-bottom:28px;"
              @mouseenter="mouseenter(item.id)"
              @mouseleave="mouseleave(item.id)"
            >
              <el-image
                style="height: 175px;min-width: 100%"
                :src="item.resource_fimage"
                @contextmenu="handleMouse"
                :fit="'cover'"
                lazy
                :class="{ 'current-image': item.id === current }"
              ></el-image>
              <div class="sample-player-box" @click="handleDetail(item.id)">
                <div class="info-block right-top">{{ item.quality }}</div>
                <div class="info-block left-top">{{ item.duration }}</div>
                <div class="video-player">
                  <video
                    @contextmenu="handleMouse"
                    :src="item.resource_path"
                    :autoplay="true"
                    loop="loop"
                    class="video"
                    v-if="item.id === current"
                  >
                    您的浏览器不支持 video 标签。
                  </video>
                </div>
              </div>
              <div class="grid-text">
                <div class="grid-oper">
                  <p class="name">{{ item.title }}</p>
                  <div>
                    <img
                      src="@/assets/images/collection-1.png"
                      @click="handleCollection(item.id)"
                    />
                    <img
                      src="@/assets/images/forward-1.png"
                      @click="handleShare(item)"
                    />
                    <img
                      v-if="item.is_zan"
                      src="@/assets/images/like-fill.png"
                      @click="handleLike(item.id, infoData.similarresource)"
                    />
                    <img
                      v-else
                      src="@/assets/images/like-1.png"
                      @click="handleLike(item.id, infoData.similarresource)"
                    />
                    <span>{{ item.fabulous }}</span>
                  </div>
                </div>
              </div>
            </div></el-col
          >
        </el-row>
        <div v-else class="empty-text">暂时还没有相关信息</div>
      </div>
    </div>
    <div class="preview-detail">
      <div class="background-wall">
        <el-image
          :src="userInfoBg"
          fit="cover"
          @contextmenu="handleMouse"
          class="bg-img"
        ></el-image>
        <div class="user-info">
          <div class="fans">
            <span class="title">粉丝</span
            ><span class="num">{{ infoData.fans }}</span>
          </div>
          <div class="like">
            <span class="title">关注</span
            ><span class="num">{{ infoData.follow }}</span>
          </div>
        </div>
        <div class="user-name" v-if="infoData">
          <div @click="jumpUCenter(infoData.user_id)">
            <el-avatar
              class="user-avatar"
              :size="118"
              :src="infoData.author_info.headimage || defaultimg"
            ></el-avatar>
          </div>

          <p class="nickname">{{ infoData.author_info.nickname }}</p>
          <p class="follow-btn" @click="handleFollow(infoData.author_info.id)">
            <span v-if="is_follow == false">+关注</span>
            <span v-else>已关注</span>
          </p>
        </div>
      </div>
      <div class="work-details">
        <h2 class="name">{{ infoData.title }}</h2>
        <div class="work-details-top">
          <p>
            时长：<span>{{ infoData.duration }}</span>
          </p>
          <p v-if="infoData.shooting_time">
            拍摄时间：<span>{{ infoData.shooting_time }}</span>
          </p>
          <p>
            拍摄地点：<span>{{ infoData.shooting_address }}</span>
          </p>
        </div>
        <div class="work-analysis">
          <div>
            <img
              v-if="infoData.is_zan"
              src="@/assets/images/like-fill.png"
              @click="handleLike(infoData.id, [infoData])"
            />
            <img
              v-else
              src="@/assets/images/like.png"
              @click="handleLike(infoData.id, [infoData])"
            />
            <span>{{ infoData.fabulous }}</span>
          </div>
          <div>
            <img
              src="@/assets/images/share.png"
              @click="handleShare(infoData)"
            />
            <span>{{ zhuanfa }}</span>
          </div>
          <div>
            <img src="@/assets/images/comment.png" />
            <span>{{ commentcount }}</span>
          </div>
          <div>
            <img
              v-if="infoData.is_shoucang"
              style="width:28px"
              src="@/assets/images/collection-select.png"
              @click="handleCollection(infoData.id)"
            />
            <img
              v-else
              style="width:28px"
              src="@/assets/images/collection-select-old.png"
              @click="handleCollection(infoData.id)"
            />
          </div>
        </div>
        <div class="introduction">
          <i></i><span>简介：{{ infoData.description }}</span>
        </div>
        <div class="work-detail-bottom">
          <p>
            作品编号：<span>{{ infoData.resource_number }}</span>
          </p>
          <p>
            拍摄方式：<span>{{ infoData.shooting_model }}</span>
          </p>
          <p>
            拍摄速度：<span>{{ infoData.shooping_speed }}</span>
          </p>
          <p>
            拍摄设备：<span>{{ infoData.shooping_equipment }}</span>
          </p>
        </div>
        <!-- <div class="delete-tips">
          如需删除，请联系邮箱：liry@cng.com.cn
        </div> -->
        <div class="tags-con">
          <div class="title">标签</div>
          <div class="tags">
            <span v-for="(item, index) in infoData.taginfo" :key="index">{{
              item.tagname
            }}</span>
          </div>
        </div>
        <div class="comment">
          <div class="comment-input">
            <el-input
              type="textarea"
              :rows="2"
              resize="none"
              show-word-limit
              maxlength="80"
              placeholder="说点什么呢....."
              v-model="comment"
            >
            </el-input>
            <div class="comment-bg" @click="sendComment">
              <img src="@/assets/images/comment-bg.png" />
              <span>评论</span>
            </div>
          </div>
          <div class="comment-con" v-if="commentList.length > 0">
            <div
              v-for="(item, i) in commentList"
              :key="item.id"
              class="comment-list"
            >
              <div class="comment-list-level comment-list-level-1">
                <div class="comment-item">
                  <div
                    @click="jumpUCenter(item.user_id)"
                    class="comment-avatar"
                  >
                    <el-avatar
                      :size="52"
                      :src="item.headimage || defaultimg"
                    ></el-avatar>
                  </div>

                  <div class="comment-box">
                    <p class="name">{{ item.user_name }}</p>
                    <p class="comment">{{ item.comment }}</p>
                  </div>
                </div>
                <div class="comment-reply">
                  <div
                    @click="
                      showReplyInput(
                        i,
                        item.id,
                        item.user_name,
                        item.user_id,
                        item.parent_id
                      )
                    "
                  >
                    <img src="@/assets/images/reply.png" />
                    <span>{{ formatTime(item.time) }}</span>
                  </div>
                  <div
                    v-if="item.user_id == user_id || author_id == user_id"
                    class="comment-delete"
                    @click="
                      handleDelete(
                        i,
                        item.id,
                        item.user_name,
                        item.user_id,
                        item.parent_id
                      )
                    "
                  >
                    <span>删除</span>
                  </div>
                </div>
              </div>
              <template v-if="item.children">
                <div
                  class="comment-list-level comment-list-level-2"
                  v-for="v in item.children"
                  :key="v.id"
                >
                  <div class="comment-item">
                    <div @click="jumpUCenter(v.user_id)" class="comment-avatar">
                      <el-avatar
                        :size="35"
                        :src="v.headimage || defaultimg"
                      ></el-avatar>
                    </div>

                    <div class="comment-box">
                      <p class="name">{{ v.user_name }}</p>
                      <p class="comment">
                        <span>@{{ v.to }}:</span>{{ v.comment }}
                      </p>
                    </div>
                  </div>
                  <div class="comment-reply">
                    <div
                      @click="
                        showReplyInput(
                          i,
                          v.id,
                          v.user_name,
                          v.user_id,
                          v.parent_id
                        )
                      "
                    >
                      <img src="@/assets/images/reply.png" />
                      <span>{{ formatTime(v.time) }}</span>
                    </div>
                    <div
                      v-if="v.user_id == user_id || author_id == user_id"
                      class="comment-delete"
                      @click="
                        handleDelete(
                          i,
                          v.id,
                          v.user_name,
                          v.user_id,
                          v.parent_id
                        )
                      "
                    >
                      <span>删除</span>
                    </div>
                  </div>
                </div>
              </template>
              <div
                v-show="_inputShow(i)"
                class="my-reply my-comment-reply comment-form"
              >
                <div class="form-box">
                  <div class="form-box-left">
                    <el-avatar :size="35" :src="userAvatar"></el-avatar>
                    <div class="input-box comment-input2">
                      <el-input
                        type="textarea"
                        :rows="2"
                        resize="none"
                        show-word-limit
                        maxlength="80"
                        :placeholder="placeholder"
                        v-model="replyComment"
                      >
                      </el-input>
                    </div>
                  </div>

                  <div class="action-box">
                    <div class="reply-btn-box">
                      <span class="reply-btn" @click="sendCommentReply(i)"
                        >发表评论</span
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="no-data" v-else>
            暂时还没有相关信息
          </div>
          <!-- <div class="more-comment" v-if="total > 0">
            <el-pagination
              :current-page.sync="currentPage"
              @current-change="handleCurrentChange"
              :page-size="10"
              layout="prev, pager, next"
              :total="total"
            >
            </el-pagination>
          </div> -->
          <!-- <div class="comment-con">
            <div v-for="item in commentList" :key="item.id">
              <div>
                <el-avatar :size="40" :src="item.headPortrait"></el-avatar>
                <div>
                  <p>{{ item.name }}</p>
                  <p>{{ item.comment }}</p>
                  <span>{{ item.time }}</span>
                </div>
              </div>
            </div>
          </div> -->
        </div>
      </div>
    </div>
    <!--分享组件-->
    <poster ref="Poster"></poster>
  </div>
</template>

<script>
import { timeTransform } from "@/utils/utils.js";
import pageMixin from "@/mixins/tbPage";
import toLogin from "@/mixins/toLogin";
import poster from "@/components/sharePoster/index.vue";
export default {
  name: "index",
  mixins: [pageMixin, toLogin],
  components: { poster },
  data() {
    return {
      index: "0", //用来获取评论内容
      infoData: "", //详情数据
      commentcount: 0, //评论数
      zhuanfa: 0, //转发数
      is_follow: false, //是否关注
      author_id: "", //作者id
      userInfoBg: require("../../../assets/images/cover.png"), //作者信息背景图
      detail: {
        userInfo: {
          name: "摄影师",
          fans: 123,
          follow: 324,
          bg: require("../../../assets/images/cover.png"),
          headPortrait: require("../../../assets/images/headPortrait.png")
        },
        name: "作品名称",
        introduction:
          "红伞伞，白杆杆，吃完一起躺板板，躺板板，睡棺棺，然后一起埋山山，埋山山，哭喊喊，亲朋都来吃饭饭，吃饭饭，有伞伞，全村一起躺板板。",
        shootingTime: "拍摄时间：2021-5-08",
        shootingLocation: "中国西藏省林芝市",
        likes: 12,
        forward: 34,
        comment: "", //评论
        replyComment: "", //回复评论
        number: "12345",
        shootingMode: "地面",
        shootingEquipment: "SONY A7r3",
        tags: [
          "雪山",
          "冰川",
          "高海拔",
          "西藏",
          "雪山",
          "冰川",
          "高海拔",
          "西藏"
        ]
      },
      defaultimg: require("../../../assets/images/headPortrait.png"),
      comment: "",
      replyComment: "", //回复评论
      commentList: [],
      current: "",
      currentPage: 1,
      total: 1,
      placeholder: "请输入评论内容...",
      replyUsername: ""
    };
  },
  created() {
    console.log("跳转陆游");
    const user_id = localStorage.getItem("user_id");
    this.user_id = user_id;
    this.resource_id = this.$route.query.id;
    // 调取获取资源详情接口
    this.handleGetDetail();
  },
  mounted() {
    // 调取列表方法
    this.getPage();
  },
  computed: {
    userAvatar() {
      const user_info = localStorage.getItem("user_info");
      return user_info && JSON.parse(user_info).headimage
        ? JSON.parse(user_info).headimage
        : this.defaultimg;
    }
  },
  watch: {
    $route() {
      if (this.$route.query.id) {
        const user_id = localStorage.getItem("user_id");
        this.user_id = user_id;
        this.resource_id = this.$route.query.id;
        // 调取获取资源详情接口
        this.handleGetDetail();
        // 调取列表方法
        this.getPage();
      }
    }
  },
  methods: {
    jumpUCenter(user_id) {
      this.$router.push({
        name: "personalCenter",
        query: { PageUserId: user_id }
      });
    },
    //点击删除评论按钮，后来新加功能
    handleDelete(i, id, user_name, user_id, parent_id) {
      console.log("handleDelete", i, id, user_name, user_id, parent_id);
      this.$api.postDeleteComment({ ids: id }).then(res => {
        if (res.data.code === 1) {
          this.$message.success("删除成功");
          // this.commentcount = this.commentcount - 1;
          // 调取评论列表方法刷新
          this.getPage();
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    handleMouse(e) {
      e.preventDefault();
    },
    handleDetail(id) {
      this.$router.push({
        name: "videoPreview",
        query: { id }
      });
      // location.reload();
    },
    lookMore() {
      this.$router.push({
        name: "personalCenter",
        query: { PageUserId: this.author_id }
      });
    },
    handleGetDetail() {
      const resource_id = this.resource_id;
      const user_id = this.user_id; //当前用户id
      this.$api.getresourceInfo({ resource_id, user_id }).then(res => {
        if (res.data.code === 1) {
          this.infoData = res.data?.data;
          this.commentcount = this.infoData?.commentcount;
          this.zhuanfa = this.infoData?.zhuanfa;
          this.is_follow = res.data?.data.is_follow;
          this.author_id = res.data?.data?.author_info?.id;
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    handleFollow(other_user_id) {
      console.log("添加关注");
      //判断是否登录
      if (!this.navigatorToLogin()) {
        return;
      }
      const user_id = this.user_id; //当前用户id
      this.$api.postFollow({ user_id, other_user_id }).then(res => {
        if (res.data.code === 1) {
          this.$message.success(res.data.msg);
          this.is_follow = res.data.data.is_follow;
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    mouseenter(id) {
      this.current = id;
    },
    mouseleave() {
      this.current = "";
    },
    _inputShow(i) {
      // console.log("_inputShow i===" + i, this.commentList[i].inputShow);
      return this.commentList[i].inputShow;
    },
    _inputHide(i) {
      this.commentList[i].inputShow = false;
    },
    showReplyInput(i, id, user_name, user_id, parent_id) {
      this.replyUsername = user_name;
      this.placeholder = "@" + user_name;
      if (this.index !== i) {
        this.commentList[this.index].inputShow = false;
      }
      this.index = i;
      this.commentList[i].inputShow = !this.commentList[i].inputShow;
      this.parent_id = parent_id;
      this.id = id;
      console.log(987654321, user_name, user_id, parent_id);
      // this.to = user_name;
      // this.toId = id;
    },
    async sendComment() {
      //判断是否登录
      if (!this.navigatorToLogin()) {
        return;
      }
      if (!this.comment) {
        this.$message({
          showClose: true,
          type: "warning",
          message: "留言不能为空"
        });
      } else {
        console.log(88888888, this);
        const { data } = await this.$api.postAddComment({
          type: 0,
          user_id: this.user_id,
          content: this.comment,
          resource_id: this.resource_id
        });
        if (data.code === 1) {
          this.$message({
            message: data.msg,
            type: "success"
          });
          let a = {};
          a.time = data.data.create_time;
          a.comment = data.data.content;
          a.user_name = data.data.user_name;
          a.user_id = data.data.user_id;
          a.parent_id = data.data.parent_id;
          a.id = data.data.id;
          a.inputShow = false;
          a.children = [];
          a.headimage = data.data.headimage || this.defaultimg;
          this.commentList.unshift(a);
          this.comment = "";
          this.commentcount = this.commentcount + 1;
        } else {
          this.$message({
            message: data.msg,
            type: "warning"
          });
        }
      }
    },
    async sendCommentReply(i) {
      //判断是否登录
      if (!this.navigatorToLogin()) {
        return;
      }
      console.log("sendCommentReplysendCommentReply", i);
      if (!this.replyComment) {
        this.$message({
          showClose: true,
          type: "warning",
          message: "评论不能为空"
        });
      } else {
        console.log(99999, this.data);
        const { data } = await this.$api.postAddComment({
          type: 0,
          user_id: this.user_id,
          resource_id: this.resource_id,
          content: this.replyComment,
          parent_id: this.id
        });
        if (data.code === 1) {
          this.$message({
            message: data.msg,
            type: "success"
          });
          let a = {};
          a.time = data.data.create_time;
          a.comment = data.data.content;
          a.user_name = data.data.user_name;
          a.user_id = data.data.user_id;
          a.parent_id = data.data.parent_id;
          a.id = data.data.id;
          a.inputShow = false;
          a.headimage = data.data.headimage || this.defaultimg;
          a.to = this.replyUsername;
          this.commentList[i].children.unshift(a);
          console.log(123, this.commentList[i]);
          this.replyComment = "";
          this._inputHide(i);
          this.commentcount = this.commentcount + 1;
        } else {
          this.$message({
            message: data.msg,
            type: "warning"
          });
        }
      }
    },
    async getList(params) {
      console.log("getListgetList", params);
      Object.assign(params, { type: 0, resource_id: this.resource_id });
      const { data } = await this.$api.getCommentList(params);
      if (data.code === 1) {
        console.log("获取评论列表", data.data);
        this.commentList = data.data;
        this.total = data.count;
        this.commentcount = data.count;
      }
    },
    formatTime(time) {
      return timeTransform(time);
    },
    async handleCollection(id) {
      //判断是否登录
      if (!this.navigatorToLogin()) {
        return;
      }
      const { data } = await this.$api.postShouCang({
        resource_id: id,
        user_id: this.user_id
      });
      if (data.code === 1) {
        this.$message({
          message: data.msg,
          type: "success"
        });
      } else {
        this.$message({
          message: data.msg,
          type: "warning"
        });
      }
      this.infoData.is_shoucang = !this.infoData.is_shoucang;
    },
    async handleShare(info) {
      console.log(99999999, info);
      //判断是否登录
      if (!this.navigatorToLogin()) {
        return;
      }
      const qrContent =
        window.location.origin + "#/video/preview?id=" + info.id + "";
      const posterInfo = {
        nickname: info.nickname,
        headimage: info.headimage,
        user_description: info.user_description,
        url: info.resource_fimage || info.resource_path,
        title: info.title,
        description: info.description,
        resource_id: info.id,
        content: qrContent
      };
      this.$refs["Poster"].show(posterInfo);
      const { data } = await this.$api.postZhuanFa({
        resource_id: info.id,
        user_id: this.user_id
      });
      if (data.code === 1) {
        console.log(data.msg);
        this.zhuanfa = this.zhuanfa + 1;
      }
    },
    async handleLike(id, list) {
      //判断是否登录
      if (!this.navigatorToLogin()) {
        return;
      }
      console.log(789, id);
      const { data } = await this.$api.postClickZan({
        resource_id: id,
        user_id: this.user_id
      });
      const resource = list.filter(i => i.id === id)[0];
      if (data.code === 1) {
        this.$message({
          message: data.msg,
          type: "success"
        });
        resource.fabulous = resource.fabulous + 1;
      } else {
        this.$message({
          message: data.msg,
          type: "warning"
        });
        resource.fabulous = resource.fabulous - 1;
      }
      resource.is_zan = !resource.is_zan;
    }
  }
};
</script>

<style lang="scss">
.picture-preview {
  padding: 0 40px 0 62px;
  display: flex;
  min-height: calc(100vh - 84px);
  .preview-works {
    flex: 1;
    padding: 63px 45px 0 0;
    .time {
      font-size: 13px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #646464;
      margin-bottom: 13px;
    }
    .video {
      width: 100%;
    }
    video::-webkit-media-controls-fullscreen-button {
      display: none !important;
    }
    .other-picture {
      margin: 117px 0 41px 0;
    }
    .similar-picture {
      padding-bottom: 233px;
    }
    .common-part {
      .empty-text {
        height: 150px;
        text-align: center;
        line-height: 150px;
      }
      .title {
        font-size: 16px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #ffffff;
        border-bottom: 1px solid rgba(255, 255, 255, 0.2);
        padding-bottom: 11px;
        margin-bottom: 21px;
        .more {
          position: relative;
          top: 8px;
          font-size: 12px;
          font-family: PingFang SC;
          font-weight: 100;
          color: #ffffff;
          float: right;
          cursor: pointer;
        }
      }
      .grid-content-box {
        padding: 0 14px !important;
        .grid-content {
          position: relative;
          .current-image {
            opacity: 0;
          }
          .sample-player-box {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 154px;
            overflow: hidden;
            z-index: 0;
            cursor: pointer;
            .video-player {
              .video {
                width: 100%;
              }
            }
            .info-block {
              z-index: 10;
              font-size: 14px;
              position: absolute;
              color: #999;
              background-color: #080808;
              padding: 2px 6px;
              border-radius: 3px;
              opacity: 0.8;
            }
            .right-top {
              right: 10px;
              top: 8px;
            }
            .left-top {
              left: 10px;
              top: 8px;
            }
          }
          .grid-text {
            display: none;
          }
          .grid-oper {
            position: absolute;
            bottom: 0;
            background: linear-gradient(
              0deg,
              rgba(0, 0, 0, 0.65) 0%,
              rgba(0, 0, 0, 0) 100%
            );
            width: 100%;
            display: flex;
            justify-content: space-between;
            height: 28px;
            align-items: center;
            padding: 0 12px;
            font-size: 14px;
            .name {
              width: 50%;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
            }
            img {
              height: 12px;
              cursor: pointer;
              margin-left: 6px;
            }
            span {
              margin-left: 3px;
            }
          }
          &:hover {
            .grid-text {
              display: block;
            }
          }
        }
      }
    }
    .video-box {
      position: relative;
      z-index: 99;
      .full-screen-mask {
        position: absolute;
        top: 30px;
        left: 0px;
        width: 100px;
        height: 80px;
        background: black;
        opacity: 0;
        border: 1px solid #ffffff;
        z-index: 10;
      }
    }
  }
  .preview-detail {
    width: 448px;
    background: #1b1b1b;
    .background-wall {
      position: relative;
      .bg-img {
        height: 152px;
        width: 100%;
      }
      .user-info {
        position: absolute;
        bottom: 0;
        height: 25px;
        background: rgba(0, 0, 0, 0.35);
        width: 100%;
        display: flex;
        align-items: center;
        padding: 5px 0;
        font-size: 12px;
        .fans {
          border-right: 1px solid #ffffff;
          width: 144px;
          text-align: center;
        }
        .like {
          width: 120px;
          text-align: center;
        }
        .title {
          font-size: 12px;
          font-family: PingFangSC-Light;
          font-weight: 300;
          color: #ffffff;
          margin: 0 10px 0 10px;
        }
        .num {
          font-size: 12px;
          font-family: PingFangSC-Medium;
          font-weight: 300;
          color: #ffffff;
        }
      }
      .user-name {
        position: absolute;
        bottom: -86px;
        right: 30px;
        text-align: center;
        .user-avatar {
          cursor: pointer;
        }
        .nickname {
          font-size: 20px;
          font-family: PingFang SC;
          font-weight: 300;
          color: #ffffff;
          padding: 6px 0;
        }
        .follow-btn {
          cursor: pointer;
          width: 51px;
          height: 18px;
          background: #ffffff;
          border: 1px solid rgba(230, 0, 33, 0.53);
          border-radius: 11px;
          font-size: 12px;
          font-family: PingFangSC-Medium;
          font-weight: 600;
          color: #e60021;
          line-height: 18px;
          margin: 0 auto;
        }
      }
    }
    .work-details {
      margin-top: 130px;
      padding: 0 30px;
      .name {
        padding: 0 0 0 10px;
        font-size: 27px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #ffffff;
        margin-bottom: 23px;
      }
      .work-details-top {
        font-size: 17px;
        font-family: PingFang SC;
        font-weight: 300;
        color: #ffffff;
        border-top: 1px solid rgba(255, 255, 255, 0.2);
        border-bottom: 1px solid rgba(255, 255, 255, 0.2);
        line-height: 28px;
        padding: 16px 0 14px 4px;
      }
      .work-analysis {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 32px 0;
        div {
          flex: 1;
          display: flex;
          align-items: center;
          justify-content: center;
          span {
            font-size: 17px;
            font-family: PingFang SC;
            font-weight: 300;
            color: #ffffff;
          }
        }
        img {
          width: 34px;
          margin-right: 12px;
          cursor: pointer;
        }
      }
      .introduction {
        display: flex;
        i {
          width: 3px;
          height: 13px;
          background: #e60021;
          border-radius: 2px;
          margin: 3px 6px 0 0;
        }
        span {
          font-size: 13px;
          font-family: PingFang SC;
          font-weight: 300;
          color: #c8c8c8;
          line-height: 19px;
        }
      }
      .work-detail-bottom {
        font-size: 13px;
        font-family: PingFang SC;
        font-weight: 300;
        color: #ffffff;
        margin: 30px 0 0px;
        p {
          line-height: 24px;
        }
      }
      .delete-tips {
        font-size: 13px;
        font-family: PingFang SC;
        font-weight: 300;
        line-height: 24px;
        color: #ffffff;
        margin: 0px 0 60px;
      }
      .tags-con {
        .title {
          width: 55px;
          height: 22px;
          background: #e60021;
          border-radius: 5px;
          line-height: 22px;
          text-align: center;
        }
        .tags {
          margin: 10px 0 79px;
          span {
            background: #2a2a2a;
            border-radius: 8px;
            padding: 5px;
            margin: 0 20px 0px 0;
            display: inline-block;
            font-size: 13px;
            font-family: PingFang SC;
            font-weight: 300;
            color: #c8c8c8;
          }
        }
      }
      .comment {
        .comment-input {
          position: relative;
          .el-textarea__inner {
            height: 78px;
            background: #707070;
            border-radius: 8px;
            color: #ffffff;
          }
          .el-input__count {
            background: #707070;
            font-size: 13px;
            font-family: PingFang SC;
            font-weight: 300;
            color: #ffffff;
          }
        }
        .comment-input2 {
          position: relative;
          flex: 1;
          margin-left: 8px;
          .el-textarea__inner {
            height: 50px !important;
            background: #707070;
            border-radius: 8px;
            color: #ffffff;
          }
        }
        .comment-bg {
          position: absolute;
          right: 0;
          display: flex;
          cursor: pointer;
          img {
            width: 51px;
            height: 30px;
          }
          span {
            position: absolute;
            top: 12px;
            font-size: 16px;
            font-family: PingFang SC;
            font-weight: 500;
            color: #ffffff;
            left: 8px;
          }
        }
        .more-comment {
          height: 40px;
          background: #141414;
          color: #595959;
          font-size: 14px;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          border-radius: 0 0 7px 7px;
        }
        .no-data {
          font-size: 16px;
          text-align: center;
          padding: 60px 0;
        }
        .comment-con {
          margin-top: 40px;
          // padding: 0 40px;
          //去掉滚动条
          // max-height: 1200px;
          // _height: 1200px;
          // overflow-y: auto;
          .comment-list {
            color: #9e9e9e;
            font-size: 14px;
            padding-top: 27px;
            border-bottom: 1px solid rgba(255, 255, 255, 0.4);
            &:last-child {
              border: none;
              margin-bottom: 30px;
            }
            .comment-list-level {
              display: flex;
              justify-content: space-between;
              padding-bottom: 50px;
            }
            // .comment-list-level-1 {
            // }

            .comment-item {
              display: flex;
              .comment-avatar {
                cursor: pointer;
              }
              .comment-box {
                margin-left: 14px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                .name {
                  font-size: 17px;
                  font-family: PingFangSC-Medium;
                  font-weight: 500;
                  color: #c8c8c8;
                  padding: 0px;
                  margin-bottom: 10px;
                }
                .comment {
                  font-size: 13px;
                  line-height: 18px;
                  font-family: PingFangSC-Light;
                  font-weight: 300;
                  color: #c8c8c8;
                }
              }
            }
            .comment-list-level-2 {
              margin-left: 60px;
              .comment-box {
                margin-left: 8px;
              }
            }
            .comment-reply {
              font-size: 10px;
              font-family: PingFang SC;
              font-weight: 300;
              color: #9e9e9e;
              display: flex;
              align-items: flex-end;
              span {
                white-space: nowrap;
              }
              img {
                height: 13px;
                margin-right: 5px;
                cursor: pointer;
              }
              .comment-delete {
                padding-left: 10px;
              }
            }
            .my-reply {
              .form-box-left {
                display: flex;
              }
              .form-box {
                .el-textarea__inner {
                  height: 78px;
                  background: #707070;
                  border-radius: 8px;
                  color: #ffffff;
                }
                .el-input__count {
                  background: #707070;
                  font-size: 13px;
                  font-family: PingFang SC;
                  font-weight: 300;
                  color: #ffffff;
                }
              }
              .action-box {
                display: flex;
                align-items: center;
                margin: 0.65rem 0;
                .reply-btn-box {
                  flex: 0 0 auto;
                  margin-left: auto;
                  .reply-btn {
                    display: inline-block;
                    width: 60px;
                    height: 21px;
                    line-height: 21px;
                    background: #e60021;
                    text-align: center;
                    font-size: 10px;
                    font-family: PingFang SC;
                    font-weight: 400;
                    color: #ffffff;
                    border-radius: 5px;
                    cursor: pointer;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>
